import { createClient } from "@sanity/client";

export const client = createClient({
  projectId: "m4jodx2s", // Replace with your actual project ID
  dataset: "production", // Replace with your dataset, e.g., 'production'
  apiVersion: "2023-10-08", // Use the current date or your preferred version
  useCdn: false, // `true` if you don't need fresh data
  token: process.env.REACT_APP_SANITY_API_TOKEN,
  ignoreBrowserTokenWarning: true,
});
