import React, { useState, useEffect } from "react";
import { DramaticFullscreen } from "./DramaticFullscreen.component";
import { DramaticFullscreenProps } from "./DramaticFullscreen.interface";
import styles from "./DramaticFullscreen.module.scss";
import { SVGIcon } from "../SVGIcon/SVGIcon.component";
import { validateName } from "../../_helpers";

export const NumberScreen: React.FC<DramaticFullscreenProps> = ({
  backgroundColor,
  textColor,
  text,
  subText,
  theme = "black", // Default theme is black
  goToNextSlide, // Access the goToNextSlide prop
  handleInput,
  metaData,
}) => {
  const [number, setNumber] = useState(metaData.inputFields.number || "");
  const [invalid, setInvalid] = useState(false);

  // Function to validate the name

  const handleSubmit = () => {
    if (validateName(number, setInvalid)) {
      if (goToNextSlide) {
        goToNextSlide();
      }
    }
  };

  useEffect(() => {
    if (handleInput) {
      handleInput(number);
    }
  }, [number]);

  // Dynamically apply autofill styles for webkit browsers
  const applyAutofillStyles = (textColor: string, backgroundColor: string) => {
    const style = document.createElement("style");
    style.id = "dynamic-autofill-style";

    style.textContent = `
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${backgroundColor} inset !important;
        -webkit-text-fill-color: ${textColor} !important;
        font-family: Nulshock !important;
      }
    `;

    document.head.appendChild(style);
  };

  useEffect(() => {
    const textColor = theme === "black" ? "white" : "black";
    const backgroundColor = theme === "black" ? "black" : "white";
    applyAutofillStyles(textColor, backgroundColor);

    // Cleanup previously applied style
    return () => {
      const styleElement = document.getElementById("dynamic-autofill-style");
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, [theme]);

  // Handle input focus (prevent scrolling on mobile)
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const currentScrollY = window.scrollY;
    window.scrollTo(0, currentScrollY);
  };

  // Determine final background and text colors
  const finalBackgroundColor =
    backgroundColor || (theme === "black" ? "black" : "white");
  const finalTextColor = textColor || (theme === "black" ? "white" : "black");

  return (
    <DramaticFullscreen
      text={text} // Pass the text prop
      subText={subText}
      backgroundColor={finalBackgroundColor} // Pass final background color
      textColor={finalTextColor} // Pass final text color
      theme={theme} // Pass the theme prop
    >
      <p className={styles.error}>
        {invalid ? "Please enter a valid phone number" : ""}
      </p>
      <div className={styles.inputField}>
        <input
          id="phone"
          type="tel" // For phone number input
          value={number}
          autoFocus
          onFocus={handleFocus} // Call the scroll prevention function
          onChange={(e) => setNumber(e.target.value)}
          autoComplete="tel" // Allow autofill for phone numbers
        />
        <button
          onClick={handleSubmit}
          className={theme === "black" ? styles.black : styles.white}
        >
          <SVGIcon
            strokeWidth={4}
            color={theme === "black" ? "white" : "black"}
          />
        </button>
      </div>
    </DramaticFullscreen>
  );
};
