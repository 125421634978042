import React from "react";
import styles from "./UploadButton.module.scss";

interface UploadButtonProps {
  noBorder?: boolean;
  onClick?: () => void; // Allow the button to trigger an action like opening the file input
  theme?: "black" | "white"; // Allow the button to have a theme
  colors?: {
    bgColor: string;
    color: string;
  }; // Allow the button to have a custom color
}

export const UploadButton: React.FC<UploadButtonProps> = ({
  noBorder = false,
  onClick, // Pass onClick handler to trigger file input
  colors,
  theme = "black",
}) => {
  return (
    <button
      className={`${styles.uploadButton} ${noBorder ? styles.noBorder : ""}`} // Apply class based on noBorder prop
      onClick={onClick} // Trigger the onClick function passed from the parent
      style={{
        border: theme === "black" ? "3px solid white" : "3px solid black", // Default border color is black
        color: colors?.color, // Default text color is black
      }}
    >
      <div className={styles.uploadButtonVerticalLine}></div>
      <div className={styles.uploadButtonHorizontalLine}></div>
    </button>
  );
};
