import ScrollAnimationWrapper from "../useScrollAnimation/useScrollAnimation.component";
import styles from "./QuoteScreen.module.scss";

export const QuoteScreen: React.FC<{
  quotes: { quote: string; author: string }[];
  header?: string;
}> = ({ quotes, header }) => {
  return (
    <div className={styles.quoteScreen}>
      <div className={styles.quoteScreenInner}>
        {quotes.map((quote, i) => (
          <div key={i} className={styles.quote}>
            <p className={styles.quoteText}>"{quote?.quote}"</p>
            <p className={styles.author}>- {quote?.author}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
