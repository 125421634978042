export const APPLICATION_QUERY = `*[_type == "application" && slug.current == $slug][0]{
    _id,
    title,
    listID,
    project,
    sceneSections[]{
      time,
      autoplay,
      fade,
      screens[]{
        ...,
        _type == "dramaticFullscreen" => {
          text,
          theme
        },
        _type == "emailScreen" => {
          text,
          subText,
          theme
        },
        _type == "messageScreen" => {
          text,
          subText,
          theme
        },
        _type == "summaryScreen" => {
          text,
          subText,
          theme
        },
        _type == "numberScreen" => {
          text,
          subText,
          theme
        },
        _type == "nameScreen" => {
          name,
          theme
        },
        _type == "uploadScreen" => {
          text,
          theme
        },
        _type == "auditionScreen" => {
          soundcloudCode,
          script,
          text,
          theme
        }
      }
    }
  }`;
