import React, { useEffect, useRef, useState } from "react";
import styles from "./FullscreenText.module.scss";
import ScrollLine from "../ScrollLine/ScrollLine.component";
import ScrollAnimationWrapper from "../useScrollAnimation/useScrollAnimation.component";

export const FullscreenText: React.FC<{
  text?: string;
  size?: "large" | "small";
  fadeinText?: boolean;
}> = ({
  text = "lorem ipsum dolor sit amet, consectetur adipiscing elit",
  size = "small",
  fadeinText = false,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.4 } // Trigger when at least 10% of the element is in view
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const checkSize = () => {
    if (size === "large") {
      return styles.large;
    }
    return styles.small;
  };

  return (
    <div
      className={`${styles.fullscreenText} ${checkSize()} ${
        isVisible ? styles.fadeIn : ""
      }`}
      ref={imageRef}
    >
      <div className={styles.grain}></div>
      <div className={styles.fadedBack}>
        <img src="/images/Scrollytell/fadedBack.jpg" alt="" />
      </div>

      <p>{text}</p>
    </div>
  );
};
