import React, { useEffect, useRef, useState } from "react";
import styles from "./ScrollyTellImage.module.scss";

export const ScrollyTellImage: React.FC<{
  imgUrl: string | undefined;
  alt?: string;
  panorama?: boolean;
  position?: "top" | "bottom" | "center";
  zoom?: boolean;
  children?: React.ReactNode;
  washedOut?: boolean;
  positionHorizontal?: number;
  svh?: boolean;
  fadein?: boolean;
}> = ({
  imgUrl,
  panorama = false,
  alt,
  position = "center",
  positionHorizontal = "center", // Assuming it's a percentage
  zoom = false,
  children,
  washedOut = false,
  svh = false,
  fadein = true,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.4 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const checkIfPanorama = () => {
    return panorama ? styles.panorama : "";
  };

  return (
    <div
      ref={imageRef}
      className={`${styles.scrollyTellImage}`}
      style={{
        zIndex: svh ? 999999999 : 0,
      }}
    >
      <div
        className={`${styles.fullScreenImage} ${checkIfPanorama()} ${
          fadein && isVisible ? styles.fadeIn : ""
        } ${svh ? styles.svh : ""}  ${!fadein ? styles.alwaysVisible : ""}`}
      >
        <div
          className={`${styles.photo} ${washedOut ? styles.washed : ""} ${
            styles[position]
          } ${zoom ? styles.zoomed : ""}`}
          style={{
            backgroundImage: `url("${imgUrl}")`,
            backgroundPosition: `${positionHorizontal}%`,
          }}
        ></div>
        {children && <div className={styles.childrenContainer}>{children}</div>}
      </div>
    </div>
  );
};
