import React, { useRef, useState, useEffect } from "react";
import { DramaticFullscreen } from "./DramaticFullscreen.component";
import { DramaticFullscreenProps } from "./DramaticFullscreen.interface";
import styles from "./DramaticFullscreen.module.scss";
// @ts-ignore
import RecordRTC from "recordrtc";
import BlinkingText from "../BlinkerText/BlinkerText.component";

export const AuditionScreen: React.FC<
  DramaticFullscreenProps & { handleFileUpload: (file: File) => void } & {
    setSkipped?: (skipped: boolean) => void;
    soundCloudStartTime?: number;
    soundcloudCode?: string; // Added optional type for soundcloudCode
  }
> = ({
  backgroundColor,
  textColor,
  text,
  subText,
  theme = "black",
  goToNextSlide,
  handleFileUpload,
  metaData,
  message,
  script,
  setSkipped,
  soundCloudStartTime = 0,
  soundcloudCode,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const recorderRef = useRef<RecordRTC | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const soundCloudRef = useRef<HTMLIFrameElement | null>(null);
  const [cameraStarted, setCameraStarted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedVideoURL, setRecordedVideoURL] = useState<string | null>(null);
  const [recordedFile, setRecordedFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [minutesAndSeconds, setMinutesAndSeconds] = useState("00:00");
  const intervalRef = useRef<number | null>(null);

  const browserIsSafari = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent
  );

  const goWithoutVideo = () => {
    if (setSkipped) {
      setSkipped(true);
    }
    if (goToNextSlide) {
      goToNextSlide();
    }
  };

  useEffect(() => {
    if (cameraStarted && streamRef.current) {
      const videoElement = videoRef.current;
      if (videoElement && streamRef.current) {
        videoElement.srcObject = streamRef.current;
        videoElement.play();
      }
    }
    console.log(metaData.post);
  }, [cameraStarted]);

  const countMinutesAndSeconds = () => {
    let seconds = 0;
    let minutes = 0;
    intervalRef.current = window.setInterval(() => {
      seconds++;
      if (seconds === 60) {
        seconds = 0;
        minutes++;
      }
      setMinutesAndSeconds(
        `${minutes < 10 ? `0${minutes}` : minutes}:${
          seconds < 10 ? `0${seconds}` : seconds
        }`
      );
    }, 1000);
  };

  const clearTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      setMinutesAndSeconds("00:00");
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      streamRef.current = stream;
      setCameraStarted(true);

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      setErrorMessage(
        "Error accessing camera or microphone. Please check permissions or browser compatibility."
      );
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null;
    }
    setCameraStarted(false);
  };

  const playSoundCloud = () => {
    if (soundCloudRef.current && soundcloudCode) {
      window.addEventListener("message", (event) => {
        if (event.origin !== "https://w.soundcloud.com") return;
        if (event.data && event.data.method === "play") {
          setTimeout(() => {
            soundCloudRef.current?.contentWindow?.postMessage(
              JSON.stringify({
                method: "seekTo",
                value: soundCloudStartTime * 1000,
              }),
              "*"
            );
          }, 500);
        }
      });

      soundCloudRef.current.contentWindow?.postMessage(
        JSON.stringify({ method: "play" }),
        "*"
      );
    }
  };

  const pauseSoundCloud = () => {
    if (soundCloudRef.current && soundcloudCode) {
      soundCloudRef.current.contentWindow?.postMessage(
        JSON.stringify({ method: "pause" }),
        "*"
      );
    }
  };

  const startRecording = () => {
    if (streamRef.current) {
      const options: RecordRTC.Options = {
        type: "video",
        mimeType: browserIsSafari ? "video/mp4" : "video/webm",
        disableLogs: true,
      };

      recorderRef.current = new RecordRTC(streamRef.current, options);
      recorderRef.current.startRecording();

      setIsRecording(true);
      countMinutesAndSeconds();
      if (soundcloudCode) playSoundCloud();
    }
  };

  const stopRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        const blob = recorderRef.current?.getBlob();
        if (blob) {
          const videoURL = URL.createObjectURL(blob);
          setRecordedVideoURL(videoURL);

          const timestamp = new Date().getTime();
          const newFilename = `${metaData.inputFields.name
            .toUpperCase()
            .replaceAll(" ", "_")}-AUDITION-VIDEO-FOR-${
            metaData.post.title
          }-IN-${metaData.post.project}-${timestamp}.mp4`;

          const recordedFile = new File([blob], newFilename, {
            type: "video/mp4",
          });

          setRecordedFile(recordedFile);
        } else {
          setErrorMessage("Recording failed. Please try again.");
        }
      });

      stopCamera();
      setIsRecording(false);
      clearTimer();
      pauseSoundCloud();
    }
  };

  const handleUploadClick = () => {
    if (recordedFile) {
      handleFileUpload(recordedFile);
      setErrorMessage(null);
      if (goToNextSlide) goToNextSlide();
    } else {
      setErrorMessage("No video recorded. Please record a video first.");
    }
  };

  return (
    <DramaticFullscreen
      text={text}
      subText={subText}
      backgroundColor={backgroundColor}
      textColor={textColor}
      theme={theme}
    >
      <div className={styles.videoSection}>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}

        {/* Conditionally render SoundCloud iframe */}
        {soundcloudCode && (
          <iframe
            ref={soundCloudRef}
            className={styles.soundCloudPlayer}
            width="100%"
            height="300"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            title="SoundCloud Player"
            src={soundcloudCode}
          ></iframe>
        )}

        {/* Video recording and controls */}
        {cameraStarted ? (
          <>
            <div className={styles.videoContainer}>
              <div className={styles.counter}>{minutesAndSeconds}</div>
              <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                className={styles.videoFeed}
              />
              <div className={styles.script}>
                {isRecording && (
                  <BlinkingText
                    infinite
                    words={["SHOW", "SOME", String(script)]}
                    speed={300}
                    fadeOutTime={3000}
                    repeatCount={3}
                    fadeOut
                  />
                )}
              </div>
            </div>
            <div className={styles.controls}>
              <button
                onClick={isRecording ? stopRecording : startRecording}
                className={`${styles.recordButton} ${
                  isRecording ? styles.recording : ""
                }`}
                style={{ borderColor: isRecording ? "red" : "white" }}
              />
            </div>
          </>
        ) : (
          <>
            {recordedVideoURL && (
              <div className={styles.recordedVideo}>
                <video src={recordedVideoURL} controls />
              </div>
            )}
            <div className={styles.uploadButtonContainer}>
              <button
                onClick={startCamera}
                className={styles.startCameraButton}
              >
                {recordedFile ? "Start over" : "Start Camera"}
              </button>
              {recordedFile && (
                <button
                  onClick={handleUploadClick}
                  className={styles.uploadButton}
                >
                  Upload
                </button>
              )}
            </div>
          </>
        )}
        {!cameraStarted && !recordedFile && (
          <button onClick={goWithoutVideo} className={styles.skipButton}>
            <p className={styles.skipText}>Skip this step</p>
          </button>
        )}
      </div>
    </DramaticFullscreen>
  );
};
