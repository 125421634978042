import React, { useRef, useState, useEffect } from "react";
import { DramaticFullscreen } from "./DramaticFullscreen.component";
import { DramaticFullscreenProps } from "./DramaticFullscreen.interface";
import styles from "./DramaticFullscreen.module.scss";
import { UploadButton } from "../Buttons/UploadButton/UploadButton.component";
import { Masonry } from "@mui/lab";
import { SVGIcon } from "../SVGIcon/SVGIcon.component";
import heic2any from "heic2any";
import { client } from "../../sanityClient";

export const UploadsScreen: React.FC<DramaticFullscreenProps> = ({
  backgroundColor,
  textColor,
  text,
  subText,
  theme = "black",
  goToNextSlide,
  handleInput,
  metaData,
  setNumberOfFiles,
  setUploadedFiles,
  uploadedFiles,
}) => {
  const [localPreviews, setLocalPreviews] = useState<string[]>([]);
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [isFilesVisible, setIsFilesVisible] = useState(false);
  const [triggerFadeIn, setTriggerFadeIn] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [invalid, setInvalid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<boolean[]>([]);

  // Helper function to crop an image to a square aspect ratio
  const cropImageToSquare = async (file: File): Promise<File> => {
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const size = Math.min(img.width, img.height);
          const canvas = document.createElement("canvas");
          canvas.width = size;
          canvas.height = size;
          const ctx = canvas.getContext("2d");

          if (ctx) {
            ctx.drawImage(
              img,
              (img.width - size) / 2,
              (img.height - size) / 2,
              size,
              size,
              0,
              0,
              size,
              size
            );

            canvas.toBlob((blob) => {
              if (blob) {
                const croppedFile = new File(
                  [blob],
                  file.name.replace(/\.[^/.]+$/, "") + "_square.jpg",
                  { type: "image/jpeg" }
                );
                resolve(croppedFile);
              } else {
                reject(new Error("Canvas conversion to Blob failed"));
              }
            }, "image/jpeg");
          } else {
            reject(new Error("Canvas context is not available"));
          }
        };
        img.onerror = reject;
        img.src = event.target?.result as string;
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    if (setNumberOfFiles) {
      setNumberOfFiles(localFiles.length);
    }
  }, [localFiles]);

  const MAX_FILES = 10; // Set the maximum number of photos

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setErrorMessage("");
    setProgress(0);
    setIsFilesVisible(false);

    const files = e.target.files;
    if (files) {
      const fileArray = Array.from(files);

      const combinedFiles = [...localFiles, ...fileArray];
      if (combinedFiles.length > MAX_FILES) {
        const slicedFiles = combinedFiles.slice(0, MAX_FILES);
        setErrorMessage(
          `Only 10 images are allowed. The first ${MAX_FILES} were kept.`
        );
        fileArray.length = 0;
        fileArray.push(...slicedFiles);
      } else if (combinedFiles.length < 3) {
        setErrorMessage(
          `Please upload at least ${3 - combinedFiles.length} more image(s) of yourself`
        );
      }

      const validFileTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/heic",
      ];
      const filteredFiles = fileArray.filter((file) =>
        validFileTypes.includes(file.type)
      );

      if (filteredFiles.length !== fileArray.length) {
        alert("Some files were not accepted because of invalid file types.");
      }

      const convertedFiles = await Promise.all(
        filteredFiles.map(async (file) => {
          if (file.type === "image/heic") {
            try {
              const convertedBlob = await heic2any({
                blob: file,
                toType: "image/jpeg",
              });
              return new File(
                [convertedBlob as Blob],
                file.name.replace(/\.heic$/, ".jpg"),
                {
                  type: "image/jpeg",
                }
              );
            } catch (error) {
              console.error("Error converting HEIC file", error);
              return null;
            }
          } else {
            return file;
          }
        })
      );

      const validConvertedFiles = convertedFiles.filter(
        (file) => file !== null
      ) as File[]; // Type assertion to remove nulls

      // Check if there's at least one file to crop
      let finalFiles;
      if (validConvertedFiles.length > 0) {
        const croppedFirstFile = await cropImageToSquare(
          validConvertedFiles[0]
        );
        finalFiles = [croppedFirstFile, ...validConvertedFiles.slice(1)];
      } else {
        finalFiles = validConvertedFiles;
      }

      const renamedFiles = finalFiles.map((file, index) => {
        const newFileName = `${metaData.inputFields.name
          .toUpperCase()
          .replaceAll(
            " ",
            "_"
          )}-as-${metaData.post.title.toUpperCase()}-in-${metaData.post.project.toUpperCase()}-image-${Date.now()}-${index}.${
          file!.type.split("/")[1]
        }`;

        return new File([file!], newFileName, { type: file!.type });
      });

      const localURLs = renamedFiles.map((file) => URL.createObjectURL(file));
      setLocalPreviews((prev) => [
        ...prev.slice(0, MAX_FILES),
        ...localURLs.slice(0, MAX_FILES),
      ]);
      setLocalFiles((prev) => [
        ...prev.slice(0, MAX_FILES),
        ...renamedFiles.slice(0, MAX_FILES),
      ]);

      setUploadStatus(new Array(renamedFiles.length).fill(false));
      renamedFiles.forEach((file, index) => uploadFileToSanity(file, index));
      simulateUploadProgress();
    }
  };

  const uploadFileToSanity = async (file: File, index: number) => {
    try {
      // Replace this line with the actual upload logic
      await client.assets.upload("image", file);

      // Update the upload status for this file
      setUploadStatus((prevStatus) => {
        const updatedStatus = [...prevStatus];
        updatedStatus[index] = true;
        return updatedStatus;
      });
    } catch (error) {
      console.error("Error uploading file to Sanity:", error);
    } finally {
      if (uploadStatus.every((status) => status)) {
        if (setUploadedFiles) {
          if (uploadedFiles !== undefined) {
            setUploadedFiles(uploadedFiles + 1);
          }
        }
      }
    }
  };

  const simulateUploadProgress = () => {
    let simulatedProgress = 0;
    setProgress(simulatedProgress);

    const interval = setInterval(() => {
      simulatedProgress += Math.floor(Math.random() * 10);
      setProgress(simulatedProgress);

      if (simulatedProgress >= 100) {
        clearInterval(interval);
        setProgress(100);
        setLoading(false);
        setIsFilesVisible(true);
      }
    }, 100);
  };

  useEffect(() => {
    if (isFilesVisible && localPreviews.length > 0) {
      setTimeout(() => {
        setTriggerFadeIn(true);
      }, 10);
    } else {
      setIsFilesVisible(false);
      setTriggerFadeIn(false);
    }
  }, [isFilesVisible, localPreviews]);

  useEffect(() => {
    if (uploadStatus.length > 0 && uploadStatus.every((status) => status)) {
      console.log("All files uploaded successfully");
    }
  }, [uploadStatus]);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = (index: number) => {
    const updatedPreviews = localPreviews.filter((_, i) => i !== index);
    const updatedFiles = localFiles.filter((_, i) => i !== index);

    setLocalPreviews(updatedPreviews);
    setLocalFiles(updatedFiles);

    if (updatedPreviews.length < 3) {
      setInvalid(true);
      setErrorMessage("Please upload at least three images of yourself");
    } else {
      setInvalid(false);
      setErrorMessage("");
    }
  };

  const handleNextSlideClick = () => {
    if (handleInput) {
      handleInput(localFiles);
    }

    if (goToNextSlide) {
      goToNextSlide();
    }
  };

  return (
    <DramaticFullscreen
      text={text}
      subText={subText}
      backgroundColor={
        backgroundColor || (theme === "black" ? "black" : "white")
      }
      textColor={textColor || (theme === "black" ? "white" : "black")}
      theme={theme}
    >
      <p className={styles.error}>{errorMessage}</p>
      <div className={styles.uploadsField}>
        {loading && (
          <div className={styles.loadingContainer}>
            <p>Uploading... </p>
            <div className={styles.loadingBar}>
              <div
                className={styles.loadingBarFill}
                style={{
                  width: `${progress}%`,
                  backgroundColor: `${textColor || (theme === "black" ? "white" : "black")}`,
                }}
              />
            </div>
          </div>
        )}

        {!loading && (
          <>
            <input
              ref={fileInputRef}
              type="file"
              accept="image/jpeg, image/png, image/gif, image/heic, image/jpg"
              style={{ display: "none" }}
              multiple
              onChange={handleFileChange}
            />
            {localPreviews.length === 0 && (
              <p>
                Upload at least three photos of yourself, and no more than 10.
              </p>
            )}
            {localPreviews.length === 0 && (
              <UploadButton
                colors={{
                  bgColor: theme === "black" ? "white" : "black",
                  color: theme === "black" ? "white" : "black",
                }}
                onClick={handleButtonClick}
                theme={theme}
              />
            )}
            {isFilesVisible && (
              <>
                <br />
                <div
                  className={`${styles.filesSection} ${triggerFadeIn ? styles.display : ""}`}
                >
                  <Masonry
                    columns={
                      window.innerWidth <= 768
                        ? 2
                        : localPreviews.length <= 3
                          ? localPreviews.length
                          : 3
                    }
                    spacing={0}
                  >
                    {localPreviews.map((preview, index) => (
                      <div key={index} className={styles.file}>
                        <div className={styles.innerPadding}>
                          <img
                            src={preview}
                            className={styles.previewImage}
                            alt={`Upload Preview ${index}`}
                          />
                          <button
                            className={styles.deleteButton}
                            onClick={() => handleRemoveImage(index)}
                          >
                            <img
                              src="https://img.icons8.com/m_rounded/512/FFFFFF/trash.png"
                              alt="Delete"
                            />
                          </button>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                  {localFiles.length > 0 && (
                    <div className={styles.looseButton}>
                      <div className={styles.looseButtonInner}>
                        <UploadButton
                          theme={theme}
                          colors={{
                            bgColor: theme === "black" ? "black" : "white",
                            color: theme === "black" ? "white" : "black",
                          }}
                          onClick={handleButtonClick}
                          noBorder
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}

        {localFiles.length >= 3 && !loading && (
          <button
            onClick={handleNextSlideClick}
            className={theme === "black" ? styles.black : styles.white}
          >
            <SVGIcon
              strokeWidth={3}
              color={theme === "black" ? "white" : "black"}
            />
          </button>
        )}
      </div>
    </DramaticFullscreen>
  );
};
