import React, { useEffect } from "react";
import styles from "./DramaticFullscreen.module.scss";
import { DramaticFullscreenProps } from "./DramaticFullscreen.interface";

export const DramaticFullscreen: React.FC<DramaticFullscreenProps> = ({
  backgroundColor,
  textColor,
  text,
  subText,
  theme = "black",
  children, // Use children to insert custom content
}) => {
  const defaultBackgroundColor = theme === "black" ? "black" : "white";
  const defaultTextColor = theme === "black" ? "white" : "black";
  const finalBackgroundColor = backgroundColor || defaultBackgroundColor;

  useEffect(() => {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      // If the background is white or the theme is white, set the meta color to white, otherwise black
      metaTag.setAttribute(
        "content",
        finalBackgroundColor === "white" ? "#FFFFFF" : "#000000"
      );
    }

    // Cleanup function (if needed in the future)
    return () => {
      if (metaTag) {
        metaTag.setAttribute("content", "#000000"); // Optionally reset the theme-color to black when the component unmounts
      }
    };
  }, [finalBackgroundColor]);

  return (
    <div
      className={styles.dramaticFullscreen}
      style={{
        background: backgroundColor || defaultBackgroundColor,
        color: textColor || defaultTextColor,
      }}
    >
      <h1>{text}</h1>
      <h2>{subText}</h2>
      {children}
      <footer className={styles.footer}>
        <img
          src={
            theme === "black"
              ? "/images/logo/Light_1024px.png"
              : "/images/logo/Dark_1024px.png"
          }
          alt=""
        />
        <p>© Indieclimb records 2024</p>
      </footer>
    </div>
  );
};
