import React, { useState, useEffect } from "react";
import styles from "./SceneSection.module.scss";

interface SceneSectionProps {
  time: number;
  autoplay?: boolean;
  loop?: boolean;
  fade?: boolean;
  onComplete?: () => void;
  children: React.ReactNode[];
  validateCurrentSlide?: () => boolean; // Validation function for current slide
}

interface ChildWithNextSlideProp {
  goToNextSlide?: () => void;
}

export const SceneSection: React.FC<SceneSectionProps> = ({
  time,
  autoplay = false,
  loop = false,
  fade = false,
  onComplete,
  children,
  validateCurrentSlide, // Validation function
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(fade);

  const totalChildren = React.Children.count(children);

  useEffect(() => {
    if (fade) {
      setTimeout(() => {
        setIsFading(false);
      }, 500);
    }
  }, [fade]);

  const changeSlide = (nextIndex: number) => {
    if (fade) {
      setIsFading(true);
      setTimeout(() => {
        setCurrentIndex(nextIndex);
        setIsFading(false);
      }, 500);
    } else {
      setCurrentIndex(nextIndex);
    }
  };

  const handleNextSlide = () => {
    // Validate current slide before changing
    if (validateCurrentSlide && !validateCurrentSlide()) {
      return; // If validation fails, do not move to next slide
    }

    const nextIndex =
      currentIndex + 1 < totalChildren
        ? currentIndex + 1
        : loop
          ? 0
          : currentIndex;
    changeSlide(nextIndex);
  };

  useEffect(() => {
    if (autoplay && (loop || currentIndex < totalChildren - 1)) {
      const interval = setTimeout(() => {
        handleNextSlide();
      }, time * 1000);

      return () => clearTimeout(interval);
    } else if (currentIndex === totalChildren - 1) {
      const lastSlideTimeout = setTimeout(() => {
        if (onComplete) {
          onComplete();
        }
      }, time * 1000);

      return () => clearTimeout(lastSlideTimeout);
    }
  }, [currentIndex, time, autoplay, loop, totalChildren]);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child as React.ReactElement<ChildWithNextSlideProp>,
        {
          goToNextSlide: handleNextSlide,
        }
      );
    }
    return child;
  });

  return (
    <div
      className={`${styles.sceneSection} ${
        fade && isFading ? styles.fadeOut : styles.fadeIn
      }`}
    >
      {childrenWithProps && childrenWithProps[currentIndex]}
    </div>
  );
};
