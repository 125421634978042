// utils.ts

// Utility function to save data to local storage
export const saveToLocalStorage = (key: string, value: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error("Failed to save to local storage:", error);
  }
};

// Utility function to get data from local storage
export const getFromLocalStorage = (key: string) => {
  try {
    const savedData = localStorage.getItem(key);
    return savedData ? JSON.parse(savedData) : null;
  } catch (error) {
    console.error("Failed to retrieve from local storage:", error);
    return null;
  }
};

// Utility function to fetch post data
export const fetchPostData = async (client: any, slug: string, query: any) => {
  try {
    const result = await client.fetch(query, { slug });
    return result;
  } catch (error) {
    console.error("Error fetching post:", error);
    throw error;
  }
};

// Utility function to upload an asset
export const uploadAsset = async (client: any, type: string, file: File) => {
  try {
    const uploadedAsset = await client.assets.upload(type, file, {
      contentType: file.type,
      filename: file.name,
    });
    return uploadedAsset.url;
  } catch (error) {
    console.error("Error uploading asset:", error);
    throw error;
  }
};
