import React from "react";
import styles from "./SongSection.module.scss";

import { useTranslation } from "react-i18next";

interface SongSectionProps {
  mainColor: string;
  releaseDate: string;
  albumCover: string;
  lowQualityBlur: string;
  description: string;
}

const SongSection: React.FC<SongSectionProps> = ({
  mainColor,
  releaseDate,
  albumCover,
  lowQualityBlur,
  description,
}) => {
  const { t } = useTranslation();

  const [isPlaying, setIsPlaying] = React.useState(false);

  const handleButtonClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className={styles.songSection}>
      <div
        className={styles.songSectionTextWrapper}
        style={{ backgroundColor: mainColor }}
      >
        <div className={styles.songSectionText}>
          <h1>{t("the_song")}</h1>
          <p className={styles.releaseDate}>
            {t("out_on_all_platforms")}:{" "}
            {new Date(releaseDate)
              .toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
              .replace(/\//g, ".")}
          </p>
          <p>{description}</p>
        </div>
      </div>
      <div
        className={styles.songSectionCover}
        style={{ backgroundColor: mainColor }}
      >
        <button
          className={`${styles.neoButton} ${!isPlaying ? styles.play : ""}`}
          onClick={handleButtonClick}
        ></button>
        <div className={styles.blurryBackground}>
          {lowQualityBlur && <img src={lowQualityBlur} alt="Album Cover" />}
        </div>
        <div
          className={`${styles.spinningRecord} ${isPlaying ? styles.spin : ""}`}
        >
          <img src={albumCover} alt="Album Visual" />
          <img
            className={styles.albumOverlay}
            src="https://cdn.sanity.io/images/m4jodx2s/production/8ed20b56d46d463075ac04ee6f054cfc4e81840d-2160x2160.png"
            alt=""
          />
          <div className={styles.blackHole}></div>
        </div>
      </div>
    </div>
  );
};

export default SongSection;
