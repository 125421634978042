import React, { useState, useEffect } from "react";
import { DramaticFullscreen } from "./DramaticFullscreen.component";
import { DramaticFullscreenProps } from "./DramaticFullscreen.interface";
import styles from "./DramaticFullscreen.module.scss";
import { SVGIcon } from "../SVGIcon/SVGIcon.component";
import { ProgressBar } from "../ProgressBar/ProgressBar.component";

export const SummaryScreen: React.FC<DramaticFullscreenProps> = ({
  backgroundColor,
  textColor,
  text,
  subText,
  theme = "black", // Default theme is black
  goToNextSlide,
  metaData,
  handleSubmit,
  message,
  isDone,
  stillUploading,
  skippedVideo,
}) => {
  const [progress, setProgress] = useState(0);
  const [statusMessage, setStatusMessage] = useState("Preparing upload...");

  // Apply autofill styles dynamically for webkit browsers
  const applyAutofillStyles = (textColor: string, backgroundColor: string) => {
    const style = document.createElement("style");
    style.id = "dynamic-autofill-style";

    style.textContent = `
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${backgroundColor} inset !important;
        -webkit-text-fill-color: ${textColor} !important;
        font-family: Nulshock !important;
      }
    `;

    document.head.appendChild(style);
  };

  useEffect(() => {
    const textColor = theme === "black" ? "white" : "black";
    const backgroundColor = theme === "black" ? "black" : "white";
    applyAutofillStyles(textColor, backgroundColor);

    // Cleanup previously applied style
    return () => {
      const styleElement = document.getElementById("dynamic-autofill-style");
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, [theme]);

  const handleButtonClick = async () => {
    try {
      if (handleSubmit) {
        await handleSubmit();
        startSimulatedProgress();
      }
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  const startSimulatedProgress = () => {
    setProgress(0); // Reset progress at the start
    setStatusMessage("Preparing upload...");

    const interval = setInterval(() => {
      setProgress((prev) => {
        let increment = Math.random() * 2 + 0.5; // Increment between 0.5% and 2.5%
        let newProgress = prev + increment;

        // Stop progress at 95% if stillUploading is true
        if (stillUploading && newProgress >= 95) {
          newProgress = 95;
        }

        // Update messages at certain milestones
        if (newProgress >= 20 && newProgress < 50) {
          setStatusMessage("Uploading images...");
        } else if (newProgress >= 50 && newProgress < 80) {
          if (skippedVideo) {
            setStatusMessage("Processing data...");
          } else {
            setStatusMessage("Processing video...");
          }
        } else if (newProgress >= 80 && newProgress < 95) {
          setStatusMessage("Finalizing...");
        }

        if (!stillUploading && newProgress >= 100) {
          clearInterval(interval); // Stop when progress reaches 100%
          setStatusMessage("Upload complete!");
          return 100;
        }

        return Math.min(newProgress, 95); // Limit to 95% while still uploading
      });
    }, 300); // Update every 300ms for a slower filling effect
  };

  // Ensure progress completes to 100% when stillUploading becomes false
  useEffect(() => {
    if (!stillUploading && progress >= 95 && progress < 100) {
      const completionInterval = setInterval(() => {
        setProgress((prev) => {
          const newProgress = prev + 1;
          if (newProgress >= 100) {
            clearInterval(completionInterval);
            setStatusMessage("Upload complete!");
            return 100; // Stop at exactly 100%
          }
          return newProgress;
        });
      }, 100);
    }
  }, [stillUploading, progress]);

  // Determine background and text colors
  const finalBackgroundColor =
    backgroundColor || (theme === "black" ? "black" : "white");
  const finalTextColor = textColor || (theme === "black" ? "white" : "black");

  // Determine display state
  const showApplyButton =
    !metaData.loading &&
    !metaData.sent &&
    !isDone &&
    !stillUploading &&
    !message;
  const showSubmitting = stillUploading || progress > 0;

  return (
    <DramaticFullscreen
      text={""}
      subText={subText}
      backgroundColor={finalBackgroundColor}
      textColor={finalTextColor}
      theme={theme}
    >
      <div className={styles.summaryScreen}>
        {showApplyButton && (
          <button
            onClick={handleButtonClick}
            className={styles.sendSchema}
            style={{
              color: finalBackgroundColor,
              backgroundColor: finalTextColor,
            }}
          >
            Apply now
          </button>
        )}

        {showSubmitting && !message && (
          <>
            <ProgressBar
              progress={Math.floor(progress)}
              color={theme === "black" ? "black" : "white"}
              backgroundColor={theme === "black" ? "white" : "black"}
            />
            <p className={styles.statusMessage}>{statusMessage}</p>
          </>
        )}

        {message && <p className={styles.finalMessage}>{message}</p>}
      </div>
    </DramaticFullscreen>
  );
};
