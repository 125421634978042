import React from "react";
import styles from "./navbar.module.scss";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export const Navbar: React.FC<{ settings: any }> = ({ settings }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = React.useState(false);

  const location = useLocation();
  const handleBurgerClick = () => {
    const burger = document.querySelector(`.${styles.burger}`);
    burger?.classList.toggle(styles.active);

    setIsExpanded(!isExpanded);

    const links = document.querySelector(`.${styles.links}`);
    links?.classList.toggle(styles.linksActive);
  };

  const getLanguageCode = () => {
    const pathParts = location.pathname.split("/");
    // Assuming the language code is always the first part of the path
    return pathParts[1];
  };

  React.useEffect(() => {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      metaTag.setAttribute("content", isExpanded ? "#FFFFFF" : "#000000");
    }
  }, [isExpanded]);

  const langCode = getLanguageCode();
  return (
    <div className={styles.navbar}>
      <Helmet>
        <meta name="theme-color" content={isExpanded ? "#FFFFFF" : "#000000"} />
      </Helmet>
      <div className={styles.burger} onClick={handleBurgerClick}>
        <div className={styles.line1}></div>
        <div className={styles.line2}></div>
        <div className={styles.line3}></div>
      </div>
      <div className={styles.navbarInner}>
        <div className={styles.navbarLogo}>
          <Link to={`/${langCode}/`}>
            <img src="/images/logo/Light_1024px.png" alt="" />
          </Link>
        </div>
        <div className={styles.links}>
          {/* <div className={styles.navbarItem}>
            <Link to="/shop">shop</Link>
          </div> */}
          <div className={styles.navbarItem} onClick={handleBurgerClick}>
            <h3>
              <Link to="https://tr.ee/v4CKFeaECI" target="_blank">
                {t("music")}
              </Link>
            </h3>
          </div>

          <div className={styles.navbarItem} onClick={handleBurgerClick}>
            <h3>
              <Link to={`/${langCode}/newsletter`}> {t("newsletter")}</Link>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};
