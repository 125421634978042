import React from "react";

export const SVGIcon: React.FC<{ strokeWidth?: number; color?: string }> = ({
  strokeWidth = 2,
  color = "white",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 2"
      viewBox="0 0 20 42.66"
      width={"100%"}
      height={"100%"}
    >
      <path
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={String(strokeWidth)}
        d="M.71 34.96l16.97-16.97v-.31L.71.71"
        data-name="Layer 1"
      ></path>
    </svg>
  );
};
