import React, { useState, useEffect } from "react";
import styles from "./BlinkerText.module.scss";

interface BlinkingTextProps {
  words: string[];
  repeatCount?: number;
  infinite?: boolean;
  speed?: number;
  coloring?: boolean;
  fadeOut?: boolean;
  fadeOutTime?: number;
  transparentBackground?: boolean;
}

const BlinkingText: React.FC<BlinkingTextProps> = ({
  words,
  repeatCount = 1,
  infinite = false,
  speed = 500,
  coloring = true,
  fadeOut = false,
  fadeOutTime,
  transparentBackground = false,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [iteration, setIteration] = useState(0);
  const [isBlinkOn, setIsBlinkOn] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [opacity, setOpacity] = useState(1);

  // Blinking interval setup
  useEffect(() => {
    if (!infinite && iteration >= repeatCount) return;

    const interval = setInterval(() => {
      setIsBlinkOn((prevBlink) => !prevBlink);
      setCurrentIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % words.length;
        if (nextIndex === 0) {
          setIteration((prevIteration) => prevIteration + 1);
        }
        return nextIndex;
      });
    }, speed);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [words, infinite, iteration, repeatCount, speed]);

  // Fade-out effect if enabled
  useEffect(() => {
    if (fadeOut && fadeOutTime) {
      const fadeOutTimeout = setTimeout(() => {
        setOpacity(0);
      }, fadeOutTime);

      const unmountTimeout = setTimeout(() => {
        setIsVisible(false);
      }, fadeOutTime + 1000);

      return () => {
        clearTimeout(fadeOutTimeout);
        clearTimeout(unmountTimeout);
      };
    }
  }, [fadeOut, fadeOutTime]);

  if (!isVisible) return null;

  return (
    <div
      className={styles.blinkerText}
      style={{
        opacity,
        transition: fadeOut ? "opacity 1s ease" : undefined,
        backgroundColor:
          coloring && isBlinkOn
            ? transparentBackground
              ? "rgba(0, 0, 0, 0.7)"
              : "black"
            : coloring
              ? transparentBackground
                ? "rgba(255, 255, 255, 0.7)"
                : "white"
              : "transparent",
        color: coloring && isBlinkOn ? "white" : coloring ? "black" : "inherit",
      }}
    >
      {words[currentIndex]}
    </div>
  );
};

export default BlinkingText;
