import React, { useState, useEffect } from "react";
import styles from "./CookieConsent.module.scss";

export const CookieConsent: React.FC<{
  theme?: "black" | "white";
  onConsent: (consent: boolean) => void;
}> = ({ onConsent, theme }) => {
  const [show, setShow] = useState(false);

  // Dynamically apply autofill styles for webkit browsers
  const applyAutofillStyles = (textColor: string, backgroundColor: string) => {
    const style = document.createElement("style");
    style.id = "dynamic-autofill-style";

    style.textContent = `
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${backgroundColor} inset !important;
          -webkit-text-fill-color: ${textColor} !important;
          font-family: Nulshock !important;
        }
      `;

    document.head.appendChild(style);
  };

  useEffect(() => {
    const textColor = theme === "black" ? "white" : "black";
    const backgroundColor = theme === "black" ? "black" : "white";
    applyAutofillStyles(textColor, backgroundColor);

    // Cleanup previously applied style
    return () => {
      const styleElement = document.getElementById("dynamic-autofill-style");
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, [theme]);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (consent === null) {
      setShow(true);
    } else {
      onConsent(consent === "true");
    }
  }, [onConsent]);

  const handleConsent = (consent: boolean) => {
    localStorage.setItem("cookieConsent", consent.toString());

    const element = document.querySelector(`.${styles.cookieConsent}`);
    if (element) {
      element.classList.remove(styles.showCookies);
    }
    setTimeout(() => {
      if (element) {
        setShow(false);
      }
    }, 5000); // Adjust the timeout as needed for the fade
    onConsent(consent);
  };

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        const element = document.querySelector(`.${styles.cookieConsent}`);
        if (element) {
          element.classList.add(styles.showCookies);
        }
      }, 5000); // Adjust the timeout as needed for the fade-in effect

      return () => clearTimeout(timer);
    }
  }, [show]);

  if (!show) return null; // If consent is already given, don't show the banner

  return (
    <div className={`${styles.cookieConsent} ${styles.fadeIn}`}>
      <div
        className={styles.cookieConsentInner}
        style={{
          backgroundColor:
            theme === "black"
              ? "rgba(255, 255, 255, 0.8)"
              : "rgba(0, 0, 0, 0.8)",
          color: theme === "black" ? "black" : "white",
        }}
      >
        <p>This site uses cookies to enhance your experience.</p>
        <button
          style={{
            backgroundColor: theme === "black" ? "black" : "white",
            color: theme === "black" ? "white" : "black",
          }}
          onClick={() => handleConsent(true)}
        >
          Accept
        </button>
        <button
          style={{
            color: theme === "black" ? "black" : "white",
            border: theme === "black" ? "1px solid black" : "1px solid white",
          }}
          onClick={() => handleConsent(false)}
        >
          Deny
        </button>
      </div>
    </div>
  );
};
