import React, { useState, useEffect } from "react";
import { DramaticFullscreen } from "./DramaticFullscreen.component";
import { DramaticFullscreenProps } from "./DramaticFullscreen.interface";
import styles from "./DramaticFullscreen.module.scss";
import { SVGIcon } from "../SVGIcon/SVGIcon.component";
import { validateName } from "../../_helpers";

export const NameScreen: React.FC<DramaticFullscreenProps> = ({
  backgroundColor,
  textColor,
  text,
  subText,
  theme = "black", // Default theme is black
  goToNextSlide, // Access the goToNextSlide prop
  handleInput,
  metaData,
}) => {
  const [name, setName] = useState(metaData.inputFields.name || "");
  const [invalid, setInvalid] = useState(false);

  // Function to validate the name

  const handleSubmit = () => {
    if (validateName(name, setInvalid)) {
      if (goToNextSlide) {
        goToNextSlide();
      }
    }
  };

  useEffect(() => {
    if (handleInput) {
      handleInput(name);
    }
  }, [name]);

  // Dynamically inject -webkit-autofill styles
  const applyAutofillStyles = (textColor: string, backgroundColor: string) => {
    let styleElement = document.getElementById("dynamic-autofill-style");

    if (!styleElement) {
      styleElement = document.createElement("style");
      styleElement.id = "dynamic-autofill-style";
      document.head.appendChild(styleElement);
    }

    styleElement.textContent = `
      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${backgroundColor} inset !important;
        -webkit-text-fill-color: ${textColor} !important;
        font-family: Nulshock !important;
      }
    `;
  };

  useEffect(() => {
    const autofillTextColor = theme === "black" ? "white" : "black";
    const autofillBackgroundColor = theme === "black" ? "black" : "white";
    applyAutofillStyles(autofillTextColor, autofillBackgroundColor);

    return () => {
      const styleElement = document.getElementById("dynamic-autofill-style");
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, [theme]);

  return (
    <DramaticFullscreen
      text={text}
      subText={subText}
      backgroundColor={backgroundColor}
      textColor={textColor}
      theme={theme}
    >
      <p className={styles.error}>{invalid ? "Please enter your name" : " "}</p>
      <div className={styles.inputField}>
        <input
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete="name"
        />
        <button
          onClick={handleSubmit}
          className={theme === "black" ? styles.black : styles.white}
        >
          <SVGIcon
            strokeWidth={4}
            color={theme === "black" ? "white" : "black"}
          />
        </button>
      </div>
    </DramaticFullscreen>
  );
};
