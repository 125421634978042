// ProgressBar.tsx
import React, { useEffect, useState } from "react";
import styles from "./ProgressBar.module.scss";

interface ProgressBarProps {
  progress?: number;
  color?: string;
  backgroundColor?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress = 29,
  color = "white",
  backgroundColor = "black",
}) => {
  const [internalProgress, setInternalProgress] = useState(0);

  useEffect(() => {
    if (progress > internalProgress) {
      const interval = setInterval(() => {
        setInternalProgress((prevProgress) =>
          prevProgress >= progress ? progress : prevProgress + 1
        );
      }, 20); // Smoothly increase progress every 20ms

      return () => clearInterval(interval);
    } else {
      setInternalProgress(progress);
    }
  }, [progress, internalProgress]);

  return (
    <div className={styles.progressBarWrapper}>
      <div
        className={styles.progressBarContainer}
        style={{ backgroundColor: backgroundColor }}
      >
        <div
          className={styles.progressBarFill}
          style={{
            width: `${internalProgress}%`,
            backgroundColor: color,
          }}
        ></div>
      </div>
      <span className={styles.progressText}>
        <p>{internalProgress}%</p>
      </span>
    </div>
  );
};
