import React from "react";
import styles from "./hero.module.scss";
import { SignupForm } from "../SignupForm/SignupForm.component";
import { useTranslation } from "react-i18next";

export const Hero: React.FC<{ image: string; title: string; colors: any }> = ({
  image,
  title,
  colors,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.hero}>
      <img className={styles.backgroundImage} src={image} alt="" />
      <h1
        className={styles.heroHeader}
        style={{ color: colors.primary?.value }}
      >
        {title}
      </h1>
      {/* <div className={styles.selections}>
        <img
          src="/images/photos/OFFICIAL SELECTION - BUDAPEST MOVIE AWARDS - 2024 (1).png"
          alt=""
        />

        <img
          src="/images/photos/OFFICIAL SELECTION - ROME PRISMA FILM AWARDS - 2023 (2).png"
          alt=""
        />
      </div> */}

      <div className={styles.listenNowSection}>
        <a
          className={styles.cta}
          href="https://tr.ee/v4CKFeaECI"
          target="_blank"
          rel="noreferrer"
        >
          {t("listen_now")}
        </a>
      </div>
    </div>
  );
};
