import React, { useState } from "react";

interface SceneSequenceProps {
  children: React.ReactNode[]; // Array of SceneSection components
}

export const SceneSequence: React.FC<SceneSequenceProps> = ({ children }) => {
  const [currentScene, setCurrentScene] = useState(0);

  const handleSceneComplete = () => {
    // Move to the next SceneSection when the current one completes
    setCurrentScene((prevScene) =>
      prevScene + 1 < children.length ? prevScene + 1 : prevScene
    );
  };

  return (
    <div>
      {/* Render only the current SceneSection */}
      {React.Children.toArray(children)[currentScene] &&
        React.cloneElement(
          React.Children.toArray(children)[currentScene] as React.ReactElement,
          {
            onComplete: handleSceneComplete, // Pass the onComplete callback to the current SceneSection
          }
        )}
    </div>
  );
};
